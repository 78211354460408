/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.icon-about-team {
  background-image: url(../img/sprite.png);
  background-position: -432px -681px;
  width: 91px;
  height: 63px;
}
.icon-blog-category {
  background-image: url(../img/sprite.png);
  background-position: -643px -681px;
  width: 30px;
  height: 31px;
}
.icon-blog-date {
  background-image: url(../img/sprite.png);
  background-position: 0px -876px;
  width: 30px;
  height: 30px;
}
.icon-contact-email {
  background-image: url(../img/sprite.png);
  background-position: -1131px -689px;
  width: 17px;
  height: 12px;
}
.icon-contact-phone {
  background-image: url(../img/sprite.png);
  background-position: -1131px -281px;
  width: 16px;
  height: 16px;
}
.icon-faq-tick {
  background-image: url(../img/sprite.png);
  background-position: -582px -876px;
  width: 34px;
  height: 8px;
}
.icon-footer-email {
  background-image: url(../img/sprite.png);
  background-position: -438px -876px;
  width: 24px;
  height: 19px;
}
.icon-footer-list {
  background-image: url(../img/sprite.png);
  background-position: -294px -876px;
  width: 24px;
  height: 19px;
}
.icon-footer-map {
  background-image: url(../img/sprite.png);
  background-position: -1131px 0px;
  width: 19px;
  height: 25px;
}
.icon-footer-tell {
  background-image: url(../img/sprite.png);
  background-position: -150px -876px;
  width: 24px;
  height: 24px;
}
.icon-footer-twitter {
  background-image: url(../img/sprite.png);
  background-position: 0px -1026px;
  width: 22px;
  height: 17px;
}
.icon-hover-red {
  background-image: url(../img/sprite.png);
  background-position: -931px -200px;
  width: 80px;
  height: 80px;
}
.icon-hover-white {
  background-image: url(../img/sprite.png);
  background-position: -931px 0px;
  width: 80px;
  height: 80px;
}
.icon-icons-f {
  background-image: url(../img/sprite.png);
  background-position: -1131px -417px;
  width: 16px;
  height: 16px;
}
.icon-icons-in {
  background-image: url(../img/sprite.png);
  background-position: -1131px -145px;
  width: 16px;
  height: 16px;
}
.icon-icons-t {
  background-image: url(../img/sprite.png);
  background-position: -1131px -553px;
  width: 16px;
  height: 16px;
}
.icon-media-calc-active {
  background-image: url(../img/sprite.png);
  background-position: -931px -595px;
  width: 58px;
  height: 75px;
}
.icon-media-calc {
  background-image: url(../img/sprite.png);
  background-position: -931px -400px;
  width: 58px;
  height: 75px;
}
.icon-media-movie-active {
  background-image: url(../img/sprite.png);
  background-position: 0px -681px;
  width: 96px;
  height: 75px;
}
.icon-media-movie {
  background-image: url(../img/sprite.png);
  background-position: -216px -681px;
  width: 96px;
  height: 75px;
}
.icon-tools-budget-hover {
  background-image: url(../img/sprite.png);
  background-position: -484px -227px;
  width: 108px;
  height: 107px;
}
.icon-tools-budget {
  background-image: url(../img/sprite.png);
  background-position: -484px 0px;
  width: 108px;
  height: 107px;
}
.icon-tools-education-hover {
  background-image: url(../img/sprite.png);
  background-position: 0px -227px;
  width: 112px;
  height: 107px;
}
.icon-tools-education {
  background-image: url(../img/sprite.png);
  background-position: -232px -227px;
  width: 112px;
  height: 107px;
}
.icon-tools-income-hover {
  background-image: url(../img/sprite.png);
  background-position: -227px -454px;
  width: 107px;
  height: 107px;
}
.icon-tools-income {
  background-image: url(../img/sprite.png);
  background-position: 0px -454px;
  width: 107px;
  height: 107px;
}
.icon-tools-lifecover-hover {
  background-image: url(../img/sprite.png);
  background-position: -712px 0px;
  width: 99px;
  height: 107px;
}
.icon-tools-lifecover {
  background-image: url(../img/sprite.png);
  background-position: -454px -454px;
  width: 99px;
  height: 107px;
}
.icon-tools-pension-hover {
  background-image: url(../img/sprite.png);
  background-position: -712px -227px;
  width: 95px;
  height: 107px;
}
.icon-tools-pension {
  background-image: url(../img/sprite.png);
  background-position: -712px -454px;
  width: 95px;
  height: 107px;
}
.icon-tools-risk-hover {
  background-image: url(../img/sprite.png);
  background-position: -242px 0px;
  width: 122px;
  height: 107px;
}
.icon-tools-risk {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 122px;
  height: 107px;
}
