
.navbar
  height auto
  margin 0
  display: block
  padding: 0
  background: #ffffff !important

.navbar-top
  padding: 15px 0 0px 0
  position: relative
  z-index: 1
  .navbar-toggler
    position: absolute
    top: 30px
    right: 30px
    z-index: 10
  .navbar-brand
    margin: 0
    height auto
    display: inline-block
    vertical-align: middle
    padding: 15px 0px
    +below(768px)
      max-width: 70%
    img
      max-width: 100%
  .md:last-child
    padding-left: 8px
    padding-right: 0

  .social-items
    padding-right: 5px
    a
      display: inline-block
      vertical-align: middle
      margin: 0 0 0 2px !important

//.top-navigation
//  .container
//    padding: 0
//  ul.navigation
//    display: table
//    width: 100%
//    margin: 0
//    padding: 0
//    list-style-type: none
//    +below(768px)
//      display: block
//    > li.nav-item
//      display table-cell
//      width: 11.1%
//      margin: 0
//      padding: 0
//      list-style-type: none
//      text-align: center
//      vertical-align: middle
//      position: relative
//      background: GRAY1
//      cursor: pointer
//      line-height: 100%
//      transition all .25s ease
//      font-family: pns
//      &.dropdown
//        .dropdown-menu
//          background: GRAY1
//          border-radius 0
//          margin: 0
//          border none
//          border-top: 0
//          +below(768px)
//            text-align: center
//          .dropdown-item
//            font-family: pns
//            font-size: 16px
//            background: GRAY1
//            padding: 8px 10px
//            color: #ffffff
//            &.active,
//            &:hover
//              background: RED1
//      +below(768px)
//        display: block
//        width: 100%
//        br
//          display: none
//      &:hover
//        background: RED1
//      &:after
//        content ''
//        position: absolute
//        width: 1px
//        background: #ffffff
//        right: 0
//        top: 6px
//        bottom: 6px
//        height: auto
//        +below(768px)
//          top auto
//          bottom 0
//          left: 0
//          width: 100%
//          height: 1px
//      &.current-menu-item,
//      &.active
//        background: RED1
//        &:after
//          display: none
//      &:last-child
//        &:after
//          display: none
//
//      > a.nav-link
//        padding: 16px 5px
//        text-transform: uppercase
//        color: #ffffff
//        font-size: 1em
//        box-sizing border-box
//
//
// navbar redesign
.navbar-redesign
  margin: 0
  display: flex
  justify-content space-between
  +below(990px)
    display: block
  .container
    padding: 0
  .navbar-brand
    width: 223px
    padding 17px 0px 17px 0px
    img
      display: block
      max-width: 100%
      width: 100%
  ul#Header_menu
    position: relative
    list-style-type: none
    margin: 0 0 0 30px
    padding: 0
    display: flex
    justify-content flex-end
    align-items center
    height: 50px
    +below(990px)
      display: block
      width: 100%
      margin: 0
    li
      padding: 0
      margin: 0
      //border-left 1px solid GRAY6
      border-left 1px solid #fff
      line-height: 100%
      height: 100%
      align-self center
      text-align: center
      //background: GRAY1
      background: #525261
      font-family: pns
      &.current-menu-item,
      &:hover
        background: RED1
      +below(990px)
        border none
        text-align: right
      &.dropdown
        .dropdown-menu
          background: #525261
          //background: GRAY1
          border-radius 0
          margin: 0
          border none
          border-top: 0
          +below(990px)
            text-align: right
            position: relative
            top: 0
          .dropdown-item
            font-family: pns
            font-size: 16px
            background: GRAY1
            background: #525261
            padding: 8px 10px
            text-align: left
            display: block
            color: #ffffff
            &.active,
            &:hover
              background: RED1
      a
        justify-content center
        font-family: "Proxima Nova Semibold", Arial, sans-serif
        text-transform: uppercase
        font-size: 16px
        display: flex
        align-items center
        height: 100%
        padding: 5px 10px
        //color: GRAY6
        color: #ffffff
        min-width: 121px

.navbar-top.navbar-thin
  .container
    padding: 0

  
  
  
ul.top-logos
  list-style-type: none
  margin: 0 -15px 0 0
  position: relative
  //padding 15px 14px 30px 0
  padding: 0
  //border-bottom 1px solid GRAY1
  display: flex
  align-items center
  justify-content flex-end
  li
    margin: 0
    padding: 0 0 0 7%
    //padding: 0
    line-height: 100%
    display: flex
    align-items center
    white-space nowrap
    +below(480px)
      width: 100%
      padding: 5px 5px
      margin-bottom: 15px
    .icon
      margin-right: 10px
    a
      color: #7d7d7d
      font-family: pns
      font-size: 15.7px
      display: flex
      align-items center
    img
      max-width: 100%
