
.content-page
  //padding: 54px 0 65px 0
  padding: 10px 0 65px 0
  &-media
    .container
      max-width: 1150px
  &-faq
    .container
      max-width: 1167px
  &-tools
    @extends .content-page-faq
  &-blog
    @extends .content-page-faq
    padding: 17px 0 40px 0
    .tools-grid__right
      margin-right: 0
    .container
      max-width: 1167px
    .navigation
      display: flex
      justify-content space-between
      margin: 30px 0
      text-align: center
      a
        text-decoration: underline
        display: block
        position: relative
        padding: 5px 15px
        &:first-child
          text-align: left
          &:after
            content: "\00AB";
            font-size: 18px
            position: absolute
            top: 4px
            left: 0
        &:last-child
          text-align: right
          &:after
            content: "\00BB";
            font-size: 18px
            position: absolute
            top: 4px
            right: 0


.blog__list
  &-item
    display: flex
    margin-bottom: 40px
    padding-bottom: 40px
    border-bottom 1px solid GRAY2
    text-decoration: none
    opacity: .8
    transition opacity .25s ease
    &:last-child
      border none
    &:hover
      opacity: 1
      text-decoration: none
    .image
      width: 215px
      img
        width: 100%
        display: block
    .content
      padding-left: 25px
      .meta
        display: block;
        color: lighten(RED1, 50%)
        font-family: "Proxima Nova Semibold", Arial,sans-serif
        text-transform: uppercase;
        font-size: 17px;
        margin-bottom: 15px;
      h5
        margin: 0;
        padding: 0px 0px 20px 0px;
        font-family: "Proxima Nova Semibold", Arial,sans-serif
        font-size: 24.6px;
        color: RED1
      p
        font-family: "Proxima Nova Light", Arial, sans-serif;
        color: #000;
        font-size: 16.3px;
        line-height: 133%;
        margin: 0



.page-title
  text-align: center
  color: RED1
  font-size: 48px
  font-family: pns
  text-transform: uppercase
  margin-bottom: 50px


.media-list
  margin: 0
  padding: 0
  display: flex
  flex-wrap wrap
  justify-content center
  width: 100%
  &__item
    text-align: center
    width: 50%
    padding: 0px 15px 110px 15px
    cursor: pointer
    +below(768px)
      width: 100%
      border-bottom: 1px solid GRAY1
      padding-bottom: 30px
      margin-bottom: 30px
      &:last-child
        border-bottom: none
    &:active,
    &:hover
      text-decoration: none
      .imgwrap
        background-image: url("../img/media-item-active.png")
      h3
        color: RED1
    &:nth-child(2n)
      border-left: 1px solid GRAY1
      +below(768px)
        border-left: none
    &.p0
      padding-bottom: 0
      +below(768px)
        padding-bottom: 30px
  .imgwrap
    height: 260px
    position: relative
    background: url("../img/media-item.png") no-repeat center
    background-size: contain
    width: 100%
    margin-bottom: 30px
    .icon
      position: absolute
      top: 50%
      left: 50%
      margin-left: -40px
      margin-top: -76px
    img.cover
      position: relative
      top: 23px
      max-width: 78%
      height: 66%
      width: 100%
      display: block
      margin: 0 auto
      +below(1030px)
        height: 60%
        top: 12%
      +below(930px)
        height: 57%
        top: 15%
      +below(860px)
        height: 48%
        top: 20%
      +below(768px)
        height: 69%
        top: 7%
        max-width: 290px
  h3
    text-align: center
    color: GRAY1
    font-family: pnl
    font-size: 31.4px
    margin: 0
    line-height: 108%
    padding: 20px 0 10px 16px


.faq
  &-grid
    display: flex
    justify-content space-between
    flex-wrap wrap
    &__left
      width: 64.8%
      +below(768px)
        width: 100%
        margin-bottom: 30px
    &__right
      width: 29.8%
      background: GRAY5
      padding: 55px 65px
      border 1px solid #efefef
      box-shadow 0 0 10px 2px #efefef
      +below(768px)
        width: 100%
      ul.footer__navigation
        list-style-type: none
        margin: 0
        padding: 0
        display: block
        li
          display: block
          margin: 0
          padding: 0
          a
            display: block
            text-transform: uppercase
            color: GRAY2
            font-size: 16px
            font-family: pnb
            margin-bottom: 14px
  &__list
    display: block
    &-item
      display: block
      position: relative
      border-bottom: 1px solid GRAY3
      &:first-child .title
        padding: 0px 0 11px 0 !important
      .icon
        position: absolute
        left: 50%
        bottom: -6px
        margin-left: -17px
      .title
        cursor: pointer
        text-align: center
        font-size: 15.7px
        font-family: pns
        text-transform: uppercase
        line-height: 100%
        padding: 35px 0 14px 0
        margin: 0
        color: RED1
        &.collapsed
          color: GRAY2
      .content
        max-width: 577px
        margin auto
        padding-bottom: 36px
        p
          font-size: 15.65px
          font-family: pnl
          line-height: 124%
          margin-bottom: 20px
          color: GRAY4
        img
          max-width: 100%
          margin-bottom: 21px

.tools-grid
  @extends .faq-grid
  &__right
    padding 55px 30px 55px 35px
    background: GRAY5
    hr
      background: GRAY3
      border none
      height: 1px
      overflow: hidden
      margin 58px auto
ul.sidebar__navigation
  @extends .footer__navigation
  padding 0 30px
  +below(990px)
    padding: 0 10px
  li
    a
      font-size: 16px
      font-family: pneb
      margin-bottom: 25px
      color: GRAY2
          


.tools__list
  list-style-type: none
  margin: 0
  padding: 0
  display: flex
  flex-wrap wrap
  &-item
    margin: 0
    padding: 0
    height: 367px
    width: 50%
    text-align: center
    +below(768px)
      width: 100%
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(7),
    &:nth-child(6)
      a
        background: GRAY5
      hr
        background: #fdfdfd !important
    h4
      font-size: 31px
      font-family: pnl
      line-height: 120%
      margin: 0
      color inherit
    .iconwrap
      display: block
      height: 162px
      line-height: 175px
    a
      height: 100%
      width: 100%
      display: block
      background: GRAY3
      color: GRAY4
      transition all .25s ease
      &:hover
        background: RED1 !important
        color: #ffffff
        text-decoration: none
    hr
      margin 18px auto 52px auto
      max-width: 63%
      border none
      height: 1px
      background: #fefefe


.page__article
  padding: 30px 0
  &-title
    @extends .page-title

.entry-content
  padding: 0
  h1,h2,h3,h4,h5,h6
    font-family: pns
  table
    p
      margin: 0
  a
    text-decoration: underline
  p,li
    strong,b
      font-family: pns



.sidebar-form
  margin: 0
  p
    text-align: center
    font-size: 15.7px
    font-family: pnl
    color: BLACK1
    line-height: 134%
    margin-bottom: 24px
    padding: 0 30px
  .form
    &-group
      margin-bottom: 16px
    &-control
      border 1px solid GRAY2
      height: 48px
      font-size: 16px
      padding: 10px 14px
      color: GRAY2
      border-radius 0
  textarea
    height: 111px !important
    resize none
  .btn
    display: block
    width: 100%
    border none
    border-radius 0
    background: RED1
    text-transform: uppercase
    font-size: 16px
    padding: 12px 15px
    font-family: pns
    color: #ffffff
    margin-bottom: 6

.media-dropdown
  text-align: right
  margin-bottom: 57px
  position: relative
  margin-top: -12px
  padding: 0 54px 0 0
  .dropdown
    display: inline-block
    &-menu
      right: 0
    &-item
      white-space normal
  .btn-link
    padding: 5px 22px
    font-size: 18px
    color: GRAY4
    font-family: pns
    &:after
      border-top-color RED1

.not-found
  text-align: center
  padding: 50px 0
  
  
.flyers-items
  @extends .blog-items
  &__list
    display: flex
    flex-wrap wrap
    justify-content space-around
    border-left 1px solid #e6e6e6
    border-right 1px solid #e6e6e6
    &-item
      border none
      width: 31%
      min-height: 1px
      padding: 0 15px
      margin-bottom: 3em
      +below(768px)
        width: 48%
      +below(480px)
        width: 100%
      img
        max-width: 100%
      h5
        padding 15px 0px
      
      &:hover
        text-decoration: none
        img
          box-shadow 0 0 3px 3px #eee
        h5
          text-decoration: underline
      
      
.page__category
  padding: 54px 0 65px 0
  
.posts
  &__list
    &-item
      text-align: left
      margin-bottom: 2em
      h3
        color: RED1
        a
          color: inherit
      p
        color: BLACK1
      .btn-warning
        margin: 0
        
        
        
body.page-template-page-sitemap
  nav
    margin-bottom: 2em


.sidebar-news
  > h4
    display: block
    text-align: center;
    color: RED1
    font-size: 28px;
    font-family: pns
    text-transform: uppercase;
    margin-bottom: 30px;
    padding-bottom: 30px
    border-bottom: 2px solid GRAY2
  &__list
    list-style-type: none
    margin: 0
    padding 0 15px
    display: block
    li
      display: block
      margin: 0
      padding: 0
      &.active
        a
          color RED1
      a
        display: block
        text-transform: uppercase
        color: #525261
        font-size: 16px
        //font-family: pnb
        font-family: pnl
        margin-bottom: 14px

.blog-meta
  padding 10px 5px 10px 5px
  margin 0px 0 30px 0
  color: #525261
  border-top 1px solid #cccbcc
  border-bottom 1px solid #cccbcc
  .date, .category
    display: inline-block
    vertical-align: middle
  .date
    margin-right: 30px

.page__article-title.blog {
  margin-top: 5px;
  font-size: 37px;
}


