
.hero
  .container
    padding: 0
  &.cutted
    height: 490px
    overflow: hidden
  &__slider
    height: auto
    &:hover,
    &:focus
      .slick-arrow
        opacity: 1
    &-item
      img
        height auto
        width: 100%
        display: block
        object-fit cover
  .slick-arrow
    display: inline-block
    vertical-align: middle
    width: 32px
    height: 32px
    z-index: 100
    position: absolute
    top: 40%
    border 1px solid #525261
    padding: 1px
    border-radius 100%
    margin: 0 0 0  0
    background-color #525261
    background-repeat no-repeat
    background-position: center
    transition all .25s ease
    opacity: 0
    &:focus
      outline 1px solid
    &.slick-prev
      left: 20px
      background-image: url("../img/icon-coursor-left.png")
    &.slick-next
      right: 20px
      background-image: url("../img/icon-coursor-right.png")
    &:before
      display: none


.welcome
  //padding: 52px 0 106px 0
  //padding: 32px 0 86px 0
  padding: 1px 0 60px 0
  text-align: center
  .container
    max-width: 772px
  h3
    text-transform: uppercase
    color RED1
    font-family: pns
    font-size:47px
    //margin-bottom: 47px
    margin-bottom: 0px
  p
    font-family: pnl
    font-size: 15.7px
    color GRAY4
    line-height: 126%
    margin-bottom: 20px


.about
  background: GRAY3
  padding-top: 117px
  padding-bottom: 64px
  position: relative
  text-align: center
  +below(768px)
    padding-bottom: 20px
  .top-icon
    left: 50%
    top: -66px
    margin-left: -69px
    background: GRAY1
    border-radius 100%
    border 8px solid GRAY3
    width: 137px
    height: 138px
    line-height: 113px
    text-align: center
    position: absolute
  h3
    text-transform: uppercase
    color RED1
    font-family: pns
    font-size:48px
    margin-bottom: 17px
  .col-md-6
    padding: 33px 39px 8px 39px
    border-left 1px solid #fcfcfc
    +below(768px)
      padding-bottom: 30px
      border-left none
      border-bottom 1px solid #fcfcfc
    &:last-child
      border-right 1px solid #fcfcfc
      +below(768px)
        border-right none
        border-bottom: none
  .imgwrap
    position: relative
    margin auto
    margin-bottom: 50px
    width: 230px
    height: 230px
    img
      border-radius 100%
      margin auto
      width: 225px
      height: 225px
    .icon
      position: absolute
      bottom: 6px
      right: 2px
  h4
    font-family: pnl
    font-size: 32px
    margin-bottom: 4px
  h5
    margin-bottom: 23px
    text-transform: uppercase
    font-size: 16px
    font-family: pns
    color RED1
  p
    color: GRAY4
    font-size: 15.6px
    line-height: 130%
    margin-bottom: 20px
    a
      color RED1
      text-decoration: underline


.media-centre
  padding-top: 53px
  padding-bottom: 64px
  background: url("../img/hero-2.png") no-repeat center BLACK1
  -webkit-background-size: cover
  background-size: cover
  text-align: center
  .container
    max-width: 1167px
  h3
    text-transform: uppercase
    color RED1
    font-family: pns
    font-size:47px
    margin-bottom: 51px
  &__list
    display: flex
    justify-content space-between
    color: #ffffff
    flex-wrap wrap
    &-item
      min-height: 400px
      width: 49.4%
      padding: 0
      position: relative
      +below(1170px)
        width: 48%
      +below(768px)
        width: 100%
        margin-bottom: 66px
        min-height: 200px
      .foreground
        opacity: 1
        transition all .25s ease
      .background
        transition all .25s ease
        opacity: 0
      img
        position: absolute
        z-index: 1
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: #000000
        object-fit cover
      .icon
        position: absolute
        left: 50%
        margin-left: -30px
        top: 62px
        z-index: 2
        +below(768px)
          top: 20px
          transform: scale(0.85)
      &:hover
        opacity: 1
        text-decoration: none
        .foreground
          opacity: 0
        .background
          opacity: 1
        h4
          border-top-color #7d7d7d
          color: #3c3c3c
          font-family: pns
      h4
        margin: 200px auto 0 auto
        max-width: 71%
        padding: 56px 0 0px 0
        border-top: 1px solid white
        font-size: 32px
        font-family: pnl
        color: #ffffff
        position: relative
        z-index: 2
        +below(768px)
          margin-top: 130px
          padding: 30px 0 20px 0
          font-size: 31px;

.blog-items
  padding-top: 58px
  padding-bottom: 7px
  .container
    max-width: 1008px
  &__title
    text-align: center
    font-family: pnl
    color: BLACK1
    font-size: 31px
    margin-bottom: 33px
  .slick-arrow
    display: inline-block
    vertical-align: middle
    width: 32px
    height: 32px
    z-index: 100
    position: absolute
    top: 49%
    margin: 0
    &.slick-prev
      left: -50px
      background: url("../img/icon-coursor-left.png") no-repeat center
      +below(990px)
        left: 0
      +below(768px)
        left: 10px
    &.slick-next
      right: -50px
      background: url("../img/icon-coursor-right.png") no-repeat center
      +below(990px)
        right: 0
      +below(768px)
        right: 10px
    &:before
      display: none
  &__list
    position: relative
    list-style-type: none
    margin: 0 0 18px 0
    padding: 0
    border-right 1px solid GRAY3
    &-item
      min-height: 440px
      padding-top: 30px
      padding-bottom: 0px
      border-left 1px solid GRAY3
      text-align: center
      img
        margin auto
        max-width: 100%
        display: inline-block
      h5
        margin: 0
        padding: 28px 12px 0px 12px
        text-transform: uppercase
        font-family: pnb
        font-size: 17px
        color: RED1
  &__news
    list-style-type: none
    margin: 0 0 21px 0
    padding: 0
    position: relative
    border-right 1px solid GRAY3
    &-item
      min-height: 410px
      padding: 32px 14px
      border-left 1px solid GRAY3
      .blog-items__news.short &
        padding: 0px 14px
        min-height: 290px
      img
        margin auto
        display: inline-block
        margin-bottom: 2px
        max-width: 100%
        +below(480px)
          display: block
          margin 5px auto

      h5
        margin: 0
        padding: 28px 0px 10px 0px
        font-family: pnb
        font-size: 16.6px
        color: RED1
      p
        font-family: pnl
        color: #000000
        font-size: 16.3px
        line-height: 133%
        margin-bottom: 9px
      .readmore
        color: RED1
        font-family: pnb
        display: block
        font-size: 16.3px
      .date
        display: block
        color: GRAY1
        font-family: pnb
        text-transform: uppercase
        font-size: 17px
        margin-bottom: 21px


.testimonials
  padding-top: 55px
  padding-bottom: 81px
  background: url("../img/icon-quot.png") no-repeat 60% top GRAY3
  text-align: center
  .container
    max-width: 1006px
  h3
    text-transform: uppercase
    color RED1
    font-family: pns
    font-size:47px
    margin-bottom: 19px
  &__list
    margin: 0
    padding: 0
    border-left 1px solid white
    border-right 1px solid white
    &-item
      margin: 0
      padding: 0
  .slick
    &-arrow
      display: inline-block
      vertical-align: middle
      width: 32px
      height: 32px
      z-index: 100
      position: absolute
      top: 49%
      margin: 0
      &.slick-prev
        left: -50px
        background: url("../img/testimonial-arrow-left.png") no-repeat center
        +below(990px)
          left: 0
        +below(768px)
          left: 10px
      &.slick-next
        right: -50px
        background: url("../img/testimonial-arrow-right.png") no-repeat center
        +below(990px)
          right: 0
        +below(768px)
          right: 10px
      &:before
        display: none
    &-dots
      list-style-type: none
      bottom: -49px
      li
        width: 16px
        height: 16px
        button
          display: block
          width: 100%
          height: 100%
          padding: 0
          background: #ffffff
          border-radius 100%
          &:before
            display: none
        &.slick-active
          button
            background: RED1
  blockquote
    display: block
    margin: 0
    padding: 25px 0 0 0
    border none
    color: BLACK1
    cite
      margin: 0
      padding: 0
      font-style normal
      display: block
      font-family: pnl
      font-size: 15.7px
      line-height: 145%
    p.name
      font-family: pns
      font-size: 16px
      margin: 0
      padding-top: 20px
      padding-bottom: 27px


.contact-us
  padding-top: 54px
  padding-bottom: 0px
  background: url("../img/hero-3.png") no-repeat center BLACK1
  -webkit-background-size: cover
  background-size: cover
  color: #ffffff
  .container
    max-width: 644px
  h3
    text-transform: uppercase
    color RED1
    font-family: pns
    font-size:47px
    margin-bottom: 47px
    text-align: center
  p
    font-size: 16px
    text-align: center
    margin-bottom: 26px
  .col-sm-6
    padding: 0 10px
  .form
    &-group
      margin-bottom: 15px
    &-control
      border-radius 0
      height: 49px
      font-size: 16px
      color: GRAY2
      font-family: pns
  textarea
    height: 112px !important
    resize none
  .btn-danger
    width: 100%
    display: block
    background: RED1
    text-transform: uppercase
    font-size: 16px
    padding: 12px 15px
    font-family: pns
    color: #ffffff
  .map-holder
    margin 17px -5px 0 -5px
    position: relative
    min-height: 370px
    iframe
      display: block
      width: 100%
      border none
      height: 497px
      position: absolute

.newsletter
  padding-top: 180px
  padding-bottom: 33px
  background: url("../img/icon-newsletter.png") no-repeat 27% 54% GRAY3
  text-align: center
  .container
    max-width: 646px
  .col-sm-6
    padding: 0 10px
  .form
    &-group
      margin-bottom: 30px
    &-control
      border-radius 0
      height: 49px
      font-size: 16px
      color: GRAY2
      font-family: pns
      border 1px solid GRAY2
  .btn-danger
    background: RED1
    text-transform: uppercase
    font-size: 16px
    padding: 12px 15px
    font-family: pns
    color: #ffffff
  h3
    font-family: pnl
    font-size: 31px
    color: BLACK1
    margin-bottom: 51px
  p
    font-family: pnl
    color: GRAY4
    font-size: 15.8px
    margin-bottom: 33px


.companies-we-work
  padding-top: 53px
  padding-bottom: 0px
  text-align: center
  h3
    font-family: pnl
    //font-size: 31.5px
    font-size: 36px
    color: BLACK1
    margin-bottom: 51px
  p
    font-family: pnl
    color: GRAY4
    font-size: 15.8px
    margin-bottom: 63px
  &__list
    align-items center
    list-style-type: none
    margin: 0
    padding: 0
    display: flex
    justify-content space-between
    flex-wrap wrap
    &.center
      justify-content center
    +below(768px)
      justify-content center
    &:last-child li
      margin-bottom: 55px !important
    li
      margin: 0 0 69px 0
      padding: 0 10px


      
.about.redesign
  margin: 0
  padding-bottom: 38px
  .col-md-6
    &:first-child
      border-left none
      position: relative
      &:before
        content ''
        position: absolute
        top: 0
        left: 0
        height: 546px
        width: 1px
        background: #fcfcfc
    &:last-child
      border-right none
      position: relative
      &:before
        content ''
        position: absolute
        top: 0
        right: 0
        height: 546px
        width: 1px
        background: #fcfcfc
  p
    margin auto
    max-width: 80%
    margin-bottom: 26px
  ul.contacts
    list-style-type: none
    margin: 0
    padding: 0
    li
      display: block
      text-align: center
      color: GRAY4
      font-size: 15.6px
      line-height: 130%
      margin-bottom: 28px
      .icon
        display: block
        margin auto
        margin-bottom: 13px
      a
        color inherit
        text-decoration: none
  ul.top-logos
    justify-content space-around
    padding 39px 14px 0px 14px
    border none
    border-top 1px solid GRAY1
    max-width: 71%
    margin auto
    margin-top: 34px
    li
      padding: 0
  .social-items
    display: flex
    justify-content space-between


.latest-news-wrap
  display: flex
  justify-content space-between
  +below(480px)
    display: block
  span.date
    display: block
    line-height: 100%
    padding 10px 5px 7px 5px
    margin 5px 0 20px 0
    color: #525261
    border-top 1px solid #cccbcc
    border-bottom 1px solid #cccbcc
  .intro
    width: 25%
    padding-right: 25px
    +below(768px)
      width: 100%
      text-align: center
      padding-right: 0
      padding-bottom: 25px
    +below(480px)
      padding-bottom: 0
    .latest-news-btns
      position: relative
      padding: 25px 0
      .slick-arrow
        position: relative
        top: 0
        left: 0
        right: 0
        display: inline-block
        vertical-align: middle
        margin: 0 5px
    h4
      text-transform capitalize
      margin: 0
      padding: 28px 0px 20px 0px
      font-family: proxima-nova-extrabold, Arial,sans-serif
      font-size: 24px
      color: RED1
      + p
        margin-bottom: 20px
    p
      font-family: pnl
      color: #000000
      font-size: 16.3px
      line-height: 133%
      margin-bottom: 9px
      margin-bottom: 19px
      &.heading
        //padding-right: 50px
        font-size: 18px
        margin-bottom: 30px
      a
        color: RED1
        font-family: pnb
        display: block
        font-size: 16.3px
  .blog-items__news.short
    width: 75%
    +below(830px)
      width: 65%
    +below(480px)
      width: 100%