font('Proxima Nova Light')
font('Proxima Nova Semibold')
font('proxima-nova-extrabold')


.icon
  display: inline-block
  vertical-align: middle
  max-width: 100%



.d, .d *
  outline 1px solid

body,html
  width: 100%
  height: 100%
  font-family: pnl


p
  font-family: pnl
  font-size: 15.7px
  line-height: 126%
  margin-bottom: 20px


body
  overflow-x hidden

.container
  width: 100%
  max-width: 1134px

