
.footer
  padding-top: 61px
  padding-bottom: 10px
  background: BLACK1
  color: #ffffff
  &__title
    font-family: pnb
    margin-bottom: 21px
    font-size: 16.3px
    text-transform: uppercase
  .grid
    //justify-content space-between
    justify-content center
    display: flex
    flex-wrap wrap
    &__col-1
      width: 33%
      +below(768px)
        width: 100%
        padding: 15px 25px
    &__col-2
      width: 20%
      +below(768px)
        width: 100%
        padding: 15px 25px
    &__col-3
      width: 29%
      +below(768px)
        padding: 15px 25px
        width: 100%
  p
    a
      text-decoration: underline
      color: #ffffff
  address
    display: block
    padding-top: 36px
    p
      position: relative
      padding-left: 45px
      margin-bottom: 28px
      font-size: 16.2px
      color: #ffffff
      .icon
        position: absolute
        top: 50%
        margin-top: -13px
        left: 0
  &__navigation
    font-family: pnb
    list-style-type: none
    margin: 0
    padding: 0
    display: block
    li
      line-height: 100%
      display: block
      a
        color: inherit
        margin-bottom: 22px
        display: block
        text-transform: uppercase
        font-size: 16px
  &__twitterfeed
    list-style-type: none
    margin: 0
    padding: 0
    li

      position: relative
      padding-left: 40px
      margin-bottom: 50px
      line-height: 139%
      .readmore
        display: block
        text-decoration: underline
        color inherit
        padding-top: 14px
      .icon
        position: absolute
        top: 5px
        left: 2px

